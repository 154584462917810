<script lang="ts">
  import CountTo from '$lib/components/CountTo.svelte';

  export let uPoints: number;
  export let accuracy: number;
  export let activeLearningTimeValue: number;
  export let activeLearningTimeDescriptor: string;
</script>

<table>
  <tr>
    <th>uTheory Points</th>
    <td>
      <span class="stat-blob whitespace-nowrap"><CountTo value={Math.round(uPoints)} /></span>
    </td>
  </tr>
  <tr>
    <th>Accuracy</th>
    <td>
      <span class="stat-blob whitespace-nowrap"><CountTo value={Math.round(accuracy)} />%</span>
    </td>
  </tr>
  <tr>
    <th>Active Learning Time</th>
    <td>
      <span class="stat-blob inline-block text-center"
        ><CountTo value={activeLearningTimeValue} /> {activeLearningTimeDescriptor}</span
      >
    </td>
  </tr>
</table>

<style lang="postcss">
  table {
    width: 100%;
    th {
      @apply font-normal text-left py-4 align-middle;
    }
    td {
      @apply text-right py-4 align-middle;
      .stat-blob {
        @apply bg-orange text-white rounded-full font-medium px-4 py-1;
      }
    }
  }
</style>
