<script context="module" lang="ts">
  import type { ClientUserStore } from 'shared/src';
  export const load = userStorePageLoadFunction;
</script>

<script lang="ts">
  import UPointsGraph from '$lib/components/charts/UPointsGraph.svelte';
  import { userStorePageLoadFunction } from '$lib/stores/getUserStore';
  import { autorun } from 'mobx';
  import { onDestroy } from 'svelte';
  import GradeToDate from '$lib/components/GradeToDate.svelte';
  import LessonsAndSkillsCompletedDoughnuts from '$lib/app/dashboard/LessonsAndSkillsCompletedDoughnuts.svelte';
  import { fly, fade } from 'svelte/transition';
  import Hr from '$lib/components/Hr.svelte';
  import DashboardLeftLinks from '$lib/app/dashboard/DashboardLeftLinks.svelte';
  import DashboardLeftUserStats from '$lib/app/dashboard/DashboardLeftUserStats.svelte';
  export let clientUserStore: ClientUserStore;

  let props: {
    firstName: string | null;
    lastName: string | null;
    highContrast: boolean;
    displayGradeToDate: boolean;
    sectionName: string;
    uPoints: number;
    accuracy: number;
    activeLearningTimeValue: number;
    activeLearningTimeDescriptor: string;
  };
  const destroyer = autorun(() => {
    props = {
      firstName: clientUserStore.firstName,
      lastName: clientUserStore.lastName,
      highContrast: clientUserStore.theme === 'high-contrast',
      displayGradeToDate: !!clientUserStore.currentSection.options.grading.visibleToStudents,
      sectionName: clientUserStore.currentSection.sectionName,
      uPoints: clientUserStore.uPoints,
      accuracy: clientUserStore.knowledgeProgress.accuracyScore,
      activeLearningTimeValue: clientUserStore.totalActiveLearningTimeValueAndDescriptor.value,
      activeLearningTimeDescriptor:
        clientUserStore.totalActiveLearningTimeValueAndDescriptor.descriptor,
    };
  });
  onDestroy(destroyer);
  $: name = props.firstName || props.lastName;
</script>

<div class="flex flex-col-reverse md:flex-row max-w-full sm:my-4 md:my-10 sm:mx-2">
  <div class="md:w-1/4 p-2 md:pr-4" in:fly={{ x: -400, duration: 1000 }}>
    <Hr class="border-green-400 border-b-2" />
    <DashboardLeftUserStats
      accuracy={props.accuracy}
      uPoints={props.uPoints}
      activeLearningTimeValue={props.activeLearningTimeValue}
      activeLearningTimeDescriptor={props.activeLearningTimeDescriptor}
    />
    <Hr class="border-green-400 border-b-2" />
    <DashboardLeftLinks {clientUserStore} />
  </div>
  <div class="card shadow-2xl p-4 flex-grow md:ml-2 md:max-w-3/4" in:fade={{ duration: 250 }}>
    <div class="mb-4">
      <h2 class="text-3xl font-bold">
        Hello{#if name}&nbsp;{name}{/if}!
      </h2>
      <p class="text-lg">Welcome to {props.sectionName}</p>
    </div>
    <div class="xs:flex xs:space-x-8 text-center mb-8">
      {#if props.displayGradeToDate}
        <div in:fly={{ x: -200, duration: 200 }}>
          <GradeToDate userStore={clientUserStore} class="mx-auto" />
        </div>
      {/if}
      {#if props.uPoints}
        <UPointsGraph
          class="h-64 flex-grow"
          userStore={clientUserStore}
          highContrast={props.highContrast}
        />
      {/if}
    </div>
    <LessonsAndSkillsCompletedDoughnuts {clientUserStore} />
  </div>
</div>

<style lang="postcss">
  h2,
  p {
    @apply text-slate-600 text-center;
  }
</style>
