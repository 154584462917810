<script context="module" lang="ts">
  import type { ClientUserStore } from 'shared/src';
  export const load = serverOrClientUserStorePageLoadFunction;
</script>

<script lang="ts">
  import Dashboard from '$lib/app/dashboard/Dashboard.svelte';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import { serverOrClientUserStorePageLoadFunction } from '$lib/stores/getUserStore';
  import PageTitle from '$lib/teach/resources/PageTitle.svelte';
  export let clientUserStore: ClientUserStore | undefined = undefined;
</script>

<PageTitle description="uTheory Dashboard" title="Dashboard" hideTitle />
{#if clientUserStore}
  <Dashboard {clientUserStore} />
{:else}
  <LoadingSpinner />
{/if}
