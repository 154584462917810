<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import Hr from '$lib/components/Hr.svelte';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import Radio from '$lib/components/Radio.svelte';
  import SimpleModal from '$lib/components/SimpleModal.svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import { autorun } from 'mobx';
  import { solfegeSystemDisplayNames } from 'shared/definitions/musicTheory/solfegeAndScaleDegrees';
  import type { ClientUserStore } from 'shared/src';
  import { onDestroy } from 'svelte';

  export let clientUserStore: ClientUserStore;
  let isOpen = false;

  let props = getProps();
  const destroy = autorun(() => (props = getProps()));
  let solfegeSystem = props.solfegeSystem;

  export const open = () => {
    state = 'inputting';
    errorMessage = '';
    props = getProps();
    solfegeSystem = props.solfegeSystem;
    isOpen = true;
  };

  function getProps() {
    return {
      solfegeSystem: clientUserStore.personalPreferences?.solfegeMethod || 'scaleDegrees',
      canChooseOwnSystem: clientUserStore.currentSection.options.systems.solfege.allowSelect,
    };
  }

  onDestroy(destroy);

  let state: 'inputting' | 'saving' | 'errored' | 'done' = 'inputting';
  let errorMessage = '';

  function save() {
    clientUserStore.setPersonalPreferences({
      solfegeMethod: solfegeSystem,
    });
    state = 'saving';
    clientUserStore
      .save({ preferences: clientUserStore.preferences })
      .then(() => {
        state = 'done';
      })
      .catch((err) => {
        errorProvider.error(err);
        errorMessage = err?.message || '';
      });
  }
</script>

<SimpleModal bind:isOpen>
  <div class="card-padding" slot="title">Solfege Preferences</div>
  <Hr />
  <div class="card-padding">
    {#if state !== 'done'}
      <p>
        Select your preferred solfege system.
        {#if !props.canChooseOwnSystem}
          (The teacher of your current class has set a required solfege system for all students.
          Your changes will be overridden by that system.)
        {/if}
      </p>

      {#each Object.entries(solfegeSystemDisplayNames) as [value, title]}
        <Radio bind:group={solfegeSystem} {value}>{title}</Radio>
      {/each}
      {#if state === 'errored'}
        Sorry, there was an error saving your preferences. {errorMessage}
      {/if}
      <div class="text-right">
        <Button disabled={state === 'saving'} on:click={save}>
          {#if state === 'saving'}
            <LoadingSpinner /><span class="sr-only">Saving preferences...</span>
          {:else}
            Save
          {/if}
        </Button>
      </div>
    {:else}
      <p>Success! Your preferences have been saved.</p>
      <div class="text-right">
        <Button on:click={() => (isOpen = false)}>Close</Button>
      </div>
    {/if}
  </div>
</SimpleModal>

<style lang="postcss">
  p {
    @apply py-2 text-gray;
  }
</style>
