<script lang="ts">
  import type { ClientUserStore } from 'shared/src';
  import { autorun } from 'mobx';
  import { onDestroy } from 'svelte';
  import { openModal } from '$lib/stores/modals.store';
  import SolfegePreferencesModal from './SolfegePreferencesModal.svelte';
  import LogoutButton from '../authentication/LogoutButton.svelte';

  export let clientUserStore: ClientUserStore;

  let props: ReturnType<typeof getProps>;
  const destroyer = autorun(() => {
    props = getProps();
  });

  function getProps() {
    return {
      isAnonymous: clientUserStore.type === 'anonymous',
      isPaid: clientUserStore.isPaid,
      hasEverSubscribed: clientUserStore.hasEverSubscribed,
      isSubscribed: clientUserStore.isSubscribed,
      theme: clientUserStore.theme,
      showPreferences: clientUserStore.allSections.some(
        (s) => s.options.systems.solfege.allowSelect || s.options.systems.rhythm.allowSelect
      ),
    };
  }

  $: if (clientUserStore) {
    props = getProps();
  }

  onDestroy(destroyer);

  let solfegePreferencesModal: SolfegePreferencesModal;
</script>

<SolfegePreferencesModal {clientUserStore} bind:this={solfegePreferencesModal} />
<ul>
  {#if props.isAnonymous}
    <li>
      <button on:click={() => openModal('register')}>Register</button>
    </li>
    <li>
      <button on:click={() => openModal('login')}>Login</button>
    </li>
  {/if}

  {#if !props.isAnonymous}
    <li>
      <a href="/app/dashboard/edit-profile">Edit Account</a>
    </li>
  {/if}

  {#if !props.isAnonymous && !props.isPaid}
    <li>
      <button on:click={() => openModal('subscribe-modal')}>Subscribe</button>
    </li>
  {/if}

  {#if props.hasEverSubscribed}
    <li>
      <a href="subscription">{props.isSubscribed ? 'Subscription' : 'Past Invoices'}</a>
    </li>
  {/if}

  {#if props.showPreferences}
    <li>
      <button on:click={() => solfegePreferencesModal.open()}> Solfege Preferences</button>
    </li>
  {/if}

  <li>
    <button on:click={() => openModal('support-modal')}>Support</button>
  </li>

  <li>
    <a
      rel="external"
      href="https://info.utheory.com/accessibility-roadmap-b5e1926d7e3a/"
      target="_blank">Accessibility</a
    >
  </li>

  <li><a target="_blank" href="/privacy">Privacy Policy</a></li>

  <li>
    <button on:click={() => clientUserStore.toggleHighContrast()}>
      Switch to {props.theme === 'normal' ? 'high-contrast' : 'default'} theme
    </button>
  </li>

  {#if !props.isAnonymous}
    <li>
      <LogoutButton class="mt-2 hover:underline text-left" {clientUserStore} />
    </li>
  {/if}
</ul>

<style lang="postcss">
  ul > li {
    @apply mt-4;
    button,
    a {
      @apply hover:underline text-left;
    }
  }
</style>
