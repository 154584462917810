<script lang="ts">
  import UDoughnut from '$lib/components/charts/UDoughnut.svelte';
  import type { ClientUserStore } from 'shared/src';
  import { autorun } from 'mobx';
  import { sortByFactory } from 'shared/src/utils/sortByFactory';
  import { onDestroy } from 'svelte';
  import { getSkillsToTrain } from './getSkillsToTrain';
  import Button from '$lib/components/Button.svelte';
  import { goto } from '$app/navigation';
  import { getLessonLink } from '../lessons/getLessonLink';
  import { getSkillsLink } from '../skills/getSkillsLink';

  export let clientUserStore: ClientUserStore;

  type AreaProgressDatum = {
    title: string;
    score: number;
    visible: boolean;
    buttonTitle: string;
    href: string;
  };
  let skillData: AreaProgressDatum[];
  let lessonData: AreaProgressDatum[];

  const destroyer = autorun(() => {
    lessonData = clientUserStore.lessonProgress.areas
      .map(
        (a): AreaProgressDatum => {
          let buttonTitle = 'Start';
          if (a.progress === 100) {
            buttonTitle = 'Repeat';
          } else if (a.progress) {
            buttonTitle = 'Continue';
          }
          return {
            title: a.title,
            score: a.progress,
            visible: a.isVisible,
            buttonTitle,
            href: getLessonLink(a.getNextIncompleteLesson().id),
          };
        }
      )
      .sort(sortByFactory('title'))
      .reverse();

    skillData = clientUserStore.knowledgeProgress.areas
      .map(
        (a): AreaProgressDatum => {
          const skillsToTrain = getSkillsToTrain(a);
          return {
            title: a.title,
            score: a.score,
            visible: a.isVisible,
            buttonTitle: 'Train skills',
            href: skillsToTrain.length ? getSkillsLink(skillsToTrain) : '/app/skills',
          };
        }
      )
      .sort(sortByFactory('title'))
      .reverse();
  });

  onDestroy(destroyer);
</script>

<h2>Lessons Completed</h2>
<div class="sm:flex">
  {#each lessonData as lessonArea}
    <div class="sm:w-1/3 text-center">
      <h3 class:disabled={!lessonArea.visible}>
        {lessonArea.title}
      </h3>
      {#if lessonArea.visible}
        <UDoughnut
          class="mx-auto mb-4 w-36 lg:w-44"
          data={[lessonArea.score]}
          total={lessonArea.score}
          totalLabel="completed"
        />
        <Button
          class="w-32 lg:w-40 py-2 rounded-sm shadow-sm hover:shadow-md transition-shadow font-medium mb-8"
          on:click={() => goto(lessonArea.href)}>{lessonArea.buttonTitle}</Button
        >
      {:else}
        <p class="text-gray-light mb-8">Area disabled by instructor.</p>
      {/if}
    </div>
  {/each}
</div>
<h2>Skills Mastered</h2>
<div class="sm:flex">
  {#each skillData as skillArea}
    <div class="sm:w-1/3 text-center">
      <h3 class="sm:sr-only" class:disabled={!skillArea.visible}>
        {skillArea.title}
      </h3>
      {#if skillArea.visible}
        <UDoughnut
          class="mx-auto mb-4 w-36 lg:w-44"
          data={[skillArea.score]}
          total={skillArea.score}
          totalLabel="completed"
        />
        <a href={skillArea.href}>
          <Button class="w-32 lg:w-40 py-2 rounded-sm shadow-sm font-medium mb-8"
            >{skillArea.buttonTitle}</Button
          >
        </a>
      {:else}
        <p class="text-gray-light mb-8">Area disabled by instructor.</p>
      {/if}
    </div>
  {/each}
</div>

<style lang="postcss">
  h2 {
    @apply font-medium text-3xl mb-2 text-slate-600 text-center;
  }

  h3 {
    @apply font-medium text-2xl mb-4 text-slate-600 leading-tight;
    &.disabled {
      @apply text-gray-200;
    }
  }

  :global(.high-contrast) {
    h3 {
      &.disabled {
        @apply text-gray-500;
      }
    }
  }
</style>
