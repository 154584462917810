import type { UserAreaKnowledgeStore } from 'shared/src/user/userKnowledge/user.area.knowledge.store';

export function getSkillsToTrain(
  area: UserAreaKnowledgeStore,
  useOptionalSkills = false,
  recursiveSkills: string[] = []
) {
  const skillsPool = useOptionalSkills ? area.optionalSkills : area.requiredSkills;

  // Take the first 5 skills that are in progress but not completed:
  const inProgress = skillsPool
    .filter((skill) => skill.score > 30 && skill.score < 90)
    .slice(0, 5)
    .map((skill) => skill.textId);

  // Now take the first 5 not-yet-started skills whose score is under 90%
  const untrained = skillsPool
    .filter((skill) => !skill.score && skill.score < 90)
    .slice(0, 5)
    .map((skill) => skill.textId);

  // If we don't have enough from those, this user has practiced a lot!
  // Take their lowest five skills:
  const belowAverageSkills = skillsPool
    .sort((a, b) => b.score - a.score)
    .slice(0, 5)
    .filter((skill) => Math.round(skill.score) < 100)
    .map((skill) => skill.textId);

  let skillsToTrain = [
    ...recursiveSkills,
    ...inProgress,
    ...untrained,
    ...belowAverageSkills,
  ].slice(0, 5);

  if (skillsToTrain.length < 5) {
    if (!useOptionalSkills) return skillsToTrain;
    // Just take the lowest five scores:
    skillsToTrain = [
      ...skillsToTrain,
      ...area.visibleSkills
        .sort((a, b) => b.score - a.score)
        .slice(0, 5)
        .map((skill) => skill.textId),
    ].slice(0, 5);
  }

  return skillsToTrain;
}
