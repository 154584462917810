<script lang="ts">
  import type { ChartData, ChartOptions } from 'chart.js';
  import Line from 'svelte-chartjs/src/Line.svelte';
  import { green, orange, red, gray } from 'design/src/tokens/colors';
  import { max } from 'shared/src/utils/max';
  import { autorun } from 'mobx';
  import { onDestroy } from 'svelte';
  import type { AbstractUserStore } from 'shared/src/user/abstract.user.store';
  import { twMerge } from 'tailwind-merge';

  let clazz: string = '';
  export { clazz as class };
  export let userStore: AbstractUserStore;
  export let highContrast: boolean;

  let props: {
    uPoints: {
      dailyPoints: number;
      date: string | Date;
      points: number;
      totalTime: number;
      dateString: string;
    }[];
    labels: string[];
    datapoints: number[];
  };
  const destroyer = autorun(() => {
    props = {
      uPoints: userStore.uPointsRecords.slice(-10),
      labels: userStore.uPointsRecords.slice(-10).map((point) => point.dateString),
      datapoints: userStore.uPointsRecords.slice(-10).map((point) => point.points),
    };
  });

  onDestroy(destroyer);

  $: maxVal = props.datapoints.reduce(max, 0);

  let options: ChartOptions<'line'>;
  $: options = {
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: true,
          lineWidth: 2,
          color: gray[50],
        },
        grace: '10%',
        // Never show negative numbers
        min: maxVal > 100 ? undefined : 0,
        // Default to a 0-50 grid for beginners
        max: maxVal > 50 ? undefined : 50,
        ticks: {
          color: highContrast ? gray[600] : gray[400],
        },
      },
      x: {
        grid: {
          display: true,
          lineWidth: 2,
          color: gray[50],
        },
        ticks: {
          color: highContrast ? gray[600] : gray[400],
        },
      },
    },
    elements: {
      line: {
        // Bezier curve tension
        tension: 0.4,
        borderColor: green[400],
      },
      point: {
        radius: 5,
        hitRadius: 20,
        backgroundColor: orange[400],
        borderColor: orange[400],
        hoverBackgroundColor: red[400],
        hoverBorderColor: red[400],
        hoverRadius: 7,
      },
    },
  };

  let data: ChartData<'line'>;
  $: data = {
    labels: props.labels,
    datasets: [
      {
        data: props.datapoints,
      },
    ],
  };
</script>

<!-- The min-w-0 below makes it possible for the chart to responsively
resize down when in a flex container. Do not remove!
c.f. https://jsfiddle.net/pgg9pz7c/3/ -->
<div class={twMerge('h-80', clazz, 'min-w-0')}>
  <Line {data} {options} aria-hidden />
  <div class="sr-only">
    <h3>uPoints Line Chart:</h3>
    <ul>
      {#each props.uPoints as uPoint}
        <li>{uPoint.dateString}: {uPoint.points}</li>
      {/each}
    </ul>
  </div>
</div>
